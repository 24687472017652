$background: #f2f3f9;
$default-color: #0d0c22;
$border: #eaedf1;

/*Color variables*/
$--primary-bg-color: #59e8aa;
$--primary-bg-hover: #0ebb70;
$--primary-bg-border: #59e8aa;
$primary-1: #59e8aa;
$--primary06: #4efbe6;
$--primary09:rgba(98, 89, 202, 0.9);
$primary-01: rgba(98, 89, 202, 0.1);
$primary-02: rgba(98, 89, 202, 0.2);
$primary-03: rgba(98, 89, 202, 0.3);
$primary-06: #4efbe6;
$primary-09: rgba(98, 89, 202, 0.9);
$primary-hover: var(--primary-bg-hover);
$primary-border: var(--primary-bg-border);
$primary-transparent: var(--primary-transparentcolor);
$darkprimary-transparent: var(--darkprimary-transparentcolor);
$transparentprimary-transparent: var(--transparentprimary-transparentcolor);
$primary-2: #d52b46;
$primary-3: #569834;
$primary-4: #4453fb;
$primary-5: #f3532f;
$secondary: #fb6b25;
$pink: #fc5296;
$teal: #1caf9f;
$purple: #8927ec;
$success: #09ad95;
$warning: #f7b731;
$danger: #f82649;
$info: #45aaf2;
$orange: #fc7303;
$red: #e73827;
$lime: #7bd235;
$dark: #343a40;
$indigo: #6574cd;
$cyan: #007ea7;
$azure: #45aaf2;
$white: #fff;
$black: #000;
$light: #f2f2f9;
$gray: #5a6970;
$green: #4ecc48;
$blue: #3223f1;
$yellow: #FBB034;


/*Gradient variables*/
$primary-gradient-1: linear-gradient(to bottom right, $primary-1 0%, $primary-06 100%);
$secondary-gradient: linear-gradient(to bottom right, #9070ff 0%, #ff5d9e 100%);
$warning-gradient: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%);
$info-gradient: linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%);
$danger-gradient: linear-gradient(to bottom right, #f1bf64 0%, #f71d36 100%);
$success-gradient: linear-gradient(to bottom right, #4be8d4 0%, #129bd2 100%);

/*white variables*/
$white-1: rgba(255, 255, 255, 0.1);
$white-2: rgba(255, 255, 255, 0.2);
$white-3: rgba(255, 255, 255, 0.3);
$white-4: rgba(255, 255, 255, 0.4);
$white-5: rgba(255, 255, 255, 0.5);
$white-6: rgba(255, 255, 255, 0.6);
$white-7: rgba(255, 255, 255, 0.7);
$white-8: rgba(255, 255, 255, 0.8);
$white-9: rgba(255, 255, 255, 0.9);
$white-05: rgba(255, 255, 255, 0.05);
$white-08: rgba(255, 255, 255, 0.08);
$white-75: rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-4: rgba(0, 0, 0, 0.4);
$black-5: rgba(0, 0, 0, 0.5);
$black-6: rgba(0, 0, 0, 0.6);
$black-7: rgba(0, 0, 0, 0.7);
$black-8: rgba(0, 0, 0, 0.8);
$black-9: rgba(0, 0, 0, 0.9);
$black-05: rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow: 0 5px 15px 5px rgba(80, 102, 224, 0.08);

$dark-theme: #30304d;
$dark-theme2: #16192f;
$dark-body: #22223d;
$dark-color: #dedefd;
$border-dark: rgba(255, 255, 255, 0.1);

$transparent-primary: $primary-1;
$transparent-theme: rgba(0, 0, 0, 0.2);
$transparent-body: var(--transparent-body);
$transparent-border: rgba(255, 255, 255, 0.2);
$transparent-color: #dedefd;